const questions = [
  // 1 Слайд \\
  {
    questionText: "Как твои клиенты заказывают такси?",
    answerOptions: [
      {
        answerText: "На сайте. Такой у любого бизнеса есть! ",
        answerResult:
          "Франчайзи «Максим» получают все и сразу: сайт, красивый телефонный номер и приложение для клиентов — его из маркетов скачали 135+ млн раз.",
        result: false,
      },
      {
        answerText: "По телефону. Подберу такой номер, чтобы легко запоминался",
        answerResult:
          "Франчайзи «Максим» получают все и сразу: сайт, красивый телефонный номер и приложение для клиентов — его из маркетов скачали 135+ млн раз.",
        result: false,
      },
      {
        answerText: "В приложении. Сейчас ведь у всех смартфоны ",
        answerResult:
          "Франчайзи «Максим» получают все и сразу: сайт, красивый телефонный номер и приложение для клиентов — его из маркетов скачали 135+ млн раз.",
        result: true,
      },
    ],
    image: "images/1.webp",
  },

  // 2 слайд
  {
    questionText:
      "Заказов меньше, чем обычно. Нужно привлекать клиентов. Что будешь делать?",
    answerOptions: [
      {
        answerText: "Ничего. Пусть они меня ищут",
        answerResult:
          "Для привлечения клиентов нужно проводить рекламные кампании. Франчайзи «Максим» получают доступ к веб-каталогу макетов и набор готовых материалов для запуска на 50 000 ₽ — в подарок!",
        result: false,
      },
      {
        answerText:
          "Буду ходить по клубам и барам и знакомиться с людьми. Кто-то точно закажет поездку",
        answerResult:
          "Для привлечения клиентов нужно проводить рекламные кампании. Франчайзи «Максим» получают доступ к веб-каталогу макетов и набор готовых материалов для запуска на 50 000 ₽ — в подарок!",
        result: false,
      },
      {
        answerText:
          "Проведу рекламную кампанию. Использую разные площадки с бюджетным размещением",
        answerResult:
          "Для привлечения клиентов нужно проводить рекламные кампании. Франчайзи «Максим» получают доступ к веб-каталогу макетов и набор готовых материалов для запуска на 50 000 ₽ — в подарок!",
        result: true,
      },
    ],
    image: "images/2.webp",
  },

  // 3 слайд
  {
    questionText:
      "У конкурентов упали сервера, и приложение перестало принимать заказы. Как избежать такой ситуации у себя?",
    answerOptions: [
      {
        answerText:
          "Никак. Все приложения падают время от времени, особенно если нагрузка большая",
        answerResult:
          "Техподдержка сведет вероятность сбоя к минимуму. У франчайзи «Максим» работу приложения контролируют круглые сутки.",
        result: false,
      },
      {
        answerText: "Это что, еще и айтишника нанимать придется?!",
        answerResult:
          "Техподдержка сведет вероятность сбоя к минимуму. У франчайзи «Максим» работу приложения контролируют круглые сутки.",
        result: false,
      },
      {
        answerText:
          "Прослежу, чтобы техподдержка была обязательным пунктом в договоре на разработку приложения",
        answerResult:
          "Техподдержка сведет вероятность сбоя к минимуму. У франчайзи «Максим» работу приложения контролируют круглые сутки.",
        result: true,
      },
    ],
    image: "images/3.webp",
  },

  // 4 слайд
  {
    questionText:
      "Кажется, клиентов стало даже слишком много: жалуются на долгое ожидание. Нужны водители. Чем собираешься их привлекать?",
    answerOptions: [
      {
        answerText: "Никто не может противостоять моей улыбке",
        answerResult:
          "Водителям важно управлять своим временем и достойно зарабатывать — это возможно благодаря балансу цены и комиссии. Еще водители «Максим» пользуются современным приложением для работы с заказами.",
        result: false,
      },
      {
        answerText:
          "Сделаю поездки в десять раз дороже, чем у конкурентов. Тогда водители будут зарабатывать в десять раз больше. Ведь так? ",
        answerResult:
          "Водителям важно управлять своим временем и достойно зарабатывать — это возможно благодаря балансу цены и комиссии. Еще водители «Максим» пользуются современным приложением для работы с заказами.",
        result: false,
      },
      {
        answerText:
          "Буду привлекать выгодами: свободным графиком, низкой комиссией",
        answerResult:
          "Водителям важно управлять своим временем и достойно зарабатывать — это возможно благодаря балансу цены и комиссии. Еще водители «Максим» пользуются современным приложением для работы с заказами.",
        result: true,
      },
    ],
    image: "images/4.webp",
  },

  // 5 слайд
  {
    questionText:
      "Ой, что-то пошло не так. У клиента не работает приложение, оставил негативный отзыв. Кто будет отвечать на обращения?",
    answerOptions: [
      {
        answerText: "Я в свободное время",
        answerResult:
          "Есть идея лучше: «Максим» подключает всех франчайзи к удаленному офису. Специалисты работают с обращениями 24/7.",
        result: false,
      },
      {
        answerText: "Никто не будет. Пусть сами разбираются",
        answerResult:
          "Есть идея лучше: «Максим» подключает всех франчайзи к удаленному офису. Специалисты работают с обращениями 24/7.",
        result: false,
      },
      {
        answerText: "Найму сотрудников. Только это дорого, наверное",
        answerResult:
          "Есть идея лучше: «Максим» подключает всех франчайзи к удаленному офису. Специалисты работают с обращениями 24/7.",
        result: true,
      },
    ],
    image: "images/5.webp",
  },
];

export default questions;
